.inner {
  /* background-color: rgb(3, 3, 29);
    color: white;
    align-items: center;
    align-self: center;
    align-content: center;
    margin: auto;
    border: solid; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
-moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.75);
box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.75); */
}
.super-inner {
  /* text-align:justify;
    margin: auto;
    justify-content: center;
    padding: 35px 30px 35px 30px; */
  /* background-color: rgb(3, 3, 29); */
  /* margin-top: 10px; */
  /* padding-bottom: 15px; */
}
h3 {
  text-align: center;
  /* background-color: rgb(3, 3, 29); */
}
p {
  margin-left: 30px;
  margin-right: 30px;
}

.img-fluid {
  width: 20em;
  height: 70px;
  margin-left: -50px;
  padding-top: 70px;
}
/* .img-header{
    
    width: 100%;
    height:  600px;


} */

.miniabu {
  /* margin-top: 4em; */
  margin: 0rem 0rem;
  background: #ebebeb;
  padding: 6rem;
}
p {
  font-size: 14px;
}
.super-inner p {
  font-size: 1.5rem;
  text-align: justify;
  padding-left: 0;
}
.rm {
  text-align: center;
  text-decoration: none;
}
.super-inner h3 {
  font-size: 2rem;
  margin: 0rem 2rem 2rem 2rem;
}
@media (max-width: 768px) {
  .miniabu {
    padding: 4rem 1rem;
  }
}
strong {
  color: slateblue;
}
a {
  text-decoration: none;
}
