.h1 {
  color: #5d8bf4;
  font-size: 3.5rem;
  padding-top: 3rem;
  text-align: center;
}
h5 {
  font-size: 3rem;
}
.card-title {
  font-size: 2rem;
}
.card-title1 {
  font-size: 1.8rem;
}
.card-title2 {
  font-size: 1.8rem;
}
.card-title3 {
  font-size: 1.7rem;
}
.card {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 14px 80px 18px 36px;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.mem {
  width: 100%;
}
