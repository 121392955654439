@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
.full{
  margin: 0;
  /* margin-top: -2em; */
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins" , sans-serif;
}
/* .full{
  margin-bottom: 20.1em;
  background-color: white;
} */
#thank-you-message {
  display: none;
}


#thank-you-message.show {
  color: rgb(73, 234, 24);
  font-weight: bolder;
	text-align: center;
  font-family: monospace;
	padding: 10px;
	background: #02c74a;
	color: rgb(255, 255, 255);
	margin-top: 25px;
  margin-left: -14px;
  margin-right: -23px;
	display: none;
  border-color: #000000;
  border-radius: 10px;
  display: block;
}

.body1{
  min-height: 100vh;
  width: 100%;
  background: #e4edf7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -8em;
  padding-top: -2em;
}
.container1{
  /* width: 80%; */
  background: #fff;
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.container1 .content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container1 .content .left-side{
  /* width: 25%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.content .left-side::before{
  content: '';
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #afafb6;
}
.content .left-side .details{
  margin: 14px;
  text-align: center;
}
.content .left-side .details i{
  font-size: 30px;
  color: #3e2093;
  margin-bottom: 10px;
}
.content .left-side .details .topic{
  font-size: 18px;
  font-weight: 500;
}
.content .left-side .details .text-one,
.content .left-side .details .text-two{
  font-size: 14px;
  color: #afafb6;
}
.container1 .content .right-side{
  width: 75%;
  margin-left: 75px;
}
.content .right-side .topic-text{
  font-size: 23px;
  font-weight: 600;
  color: #3e2093;
}
.right-side .input-box{
  height: 50px;
  width: 100%;
  padding-left: 2em;
  margin-right: 0;
  margin: 12px 0;
}
.button{
  padding-left: 8em;

}
/* .pp{
  padding-left: 8em;
} */
.right-side .input-box input,
.right-side .input-box textarea{
  height: 100%;
  width: 94%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #F0F1F8;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.right-side .message-box{
  min-height: 110px;
}
.right-side .input-box textarea{
  padding-top: 6px;
}
.right-side .button{
  display: inline-block;
  margin-top: 12px;
}
.right-side .button button[type="button"]{
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #3e2093;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button button[type="button"]:hover{
  background: #5029bc;
}

@media (max-width: 950px) {
  .container1{
    width: 90%;
    padding: 30px 40px 40px 35px ;
  }
  .container1 .content .right-side{
   width: 75%;
   margin-left: 55px;
}
}
@media (max-width: 820px) {
  .container1{
    margin: 40px 0;
    height: 100%;
  }
  .container1 .content{
    flex-direction: column-reverse;
  }
 .container1 .content .left-side{
   width: 100%;
   flex-direction: row;
   margin-top: 40px;
   justify-content: center;
   flex-wrap: wrap;
 }
 .container1 .content .left-side::before{
   display: none;
 }
 .container1 .content .right-side{
   width: 100%;
   margin-left: 0;
 }
}

