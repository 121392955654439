.post{
  height: 89vh;
  text-align: center;
  padding: 3rem 0rem;
}

/* ___________________________ */
.box{
  width: 300px;
  box-shadow: 2px 2px 30px rgba(0,0,0,0.2);
  border-radius: 10px;
  overflow: hidden;
  margin: 13px 10px 17px 10px;
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%); */

}
.cardimg{
  height: 50vh;
  position: relative;

}
.cardimg img{
  /* width: 100%;
  height: 50vh; */
  object-fit: cover;
  box-sizing: border-box;
}
.detailbox{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.type1{
  display: flex;
  flex-direction: column;

}
.type1 a{
  color:#222222;
  margin: 5px 0px;
  font-weight: 700;
  letter-spacing: 0.5px ;
  padding-right: 8px;
}
.type1 span{
  color: rgba(26,26,26,0.5);
}


.swiper {
  width: 78%;
  height: 95%;
}


.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.ssi {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 806px) {
  .cardimg {
    height: 30vh;
    
}
.ssi {
  
  width: 100%;
  height: 100%;
  
}
.swiper{
  
  width: 78%;
  height: 78%;
}

}
@media screen and (max-width: 520px) {
 
  .box{
    margin: 0;
  }
  .cardimg {
    height: 15vh;
    
}
.post{
  height: 59vh;
}
.ssi {
  
  width: 100%;
  height: 100%;
  
}
.swiper{
  /* width: 92%;
  height: 50%; */
  width: 92%;
  height: 88%;
}
.swiper-slide{
  font-size: 9px;
}
}