.testimonials {
    padding: 80px 0;
    /* background: url("../img/bitbuilding.jpeg") no-repeat; */
    background-position:  center bottom;
    background-size: cover;
    position: relative;
  }
  .testh4{
    color: white;
  }
  .testimonials::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(2, 2, 2, 0.7);
  }
  
  .testimonials .section-header {
    margin-bottom: 40px;
  }
  .testimonial-item{
    background-color:  rgba(255, 255, 255, 0);;
    width: 100%;
  }
  .testimonials .testimonial-item {
    text-align: center;
    color: rgba(255, 255, 255, 0);
  }
  .testimonial-img{
    width: 30%;
    border-radius: 50%;
    border: 6px solid rgba(255, 255, 255, 0.15);
    margin: 0 auto; 
   
  }

  .testimonials .testimonial-item  {
    width: 200px;
    border-radius: 50%;
    border: 6px solid rgba(255, 255, 255, 0.15);
    margin: 0 auto;
    
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #fff;
  }
  
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #ddd;
    margin: 0 0 15px 0;
  }
  
  .testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
    color: rgba(255, 255, 255, 0.4);
    font-size: 26px;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
    color: #eee;
  }
  
  .testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 1;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #8fc04e;
  }
  
  
  @media (min-width: 992px) {
    .testimonials .testimonial-item p {
      width: 80%;
    }
   
  }
  @media screen and (max-width: 520px) {
    .testimonial-img{
        width: 40%;

    }
  }