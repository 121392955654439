/* -------------------------NavBar css------------------- */
.container-fluid {
  background-color: #303983cc;
  position: absolute;
  top: -0.1rem;
  left: 0rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  width: 2em;
  height: 2em;
  border-radius: 0.5rem;
}

.navbar {
  z-index: 99;
  background-color: #303983;
  padding: 0.5rem 2rem;
  height: 8.4vh;
}
@media (max-width: 576px) {
  .navbar {
    height: 5.5vh;
  }
}
@media (max-width: 411px) {
  .navbar {
    height: 6vh;
  }
}
@media (max-width: 360px) {
  .navbar {
    height: 7vh;
  }
}
@media (max-height: 801px) and (min-height: 799px) {
  .navbar {
    height: 7.3vh;
  }
}
@media (max-height: 667px) and (min-height: 666px) {
  .navbar {
    height: 7.6vh;
  }
}
@media (max-height: 1024px) and (min-height: 1023px) {
  .navbar {
    height: 5.8vh;
  }
}
@media (max-height: 720px) and (min-height: 719px) {
  .navbar {
    height: 7vh;
  }
}
@media (max-height: 1181px) and (min-height: 1179px) {
  .navbar {
    height: 5vh;
  }
}
@media (max-height: 1369px) and (min-height: 1367px) {
  .navbar {
    height: 4.3vh;
  }
}
.navbar-brand {
  margin: 0rem 1.5rem;
}
.navbar-brand a:hover {
  transition: all 0.2s ease-in;
}
.navbar .navbar-brand img {
  margin: 0 0.9rem;
}
@media (max-width: 576px) {
  .navbar .navbar-brand img {
    margin: 0 0.9rem 0 0.3rem;
  }
  .navBit {
    height: 32px;
    width: 27px;
  }
  .navNss {
    height: 31px;
    width: 31px;
  }
}
.navbar a {
  color: white;
  font-size: 1.7rem;
  padding: 1rem 0rem;
}
.navbar a:hover {
  transition: all 0.2s ease-in;
  color: #6199eb;
}
.navbar-nav .nav-link {
  margin-right: 2.5rem;
}

@media (min-width: 576px) and (max-width: 826px) {
  .navbar a {
    font-size: 1rem;
  }
  .navbar-nav .nav-link {
    margin-right: 1.5rem;
  }
}
@media (max-width: 342px) {
  .navbar a {
    font-size: 1.7rem;
  }
}
.droplinks {
  color: white;
  padding: 0rem 1rem;
  margin-bottom: 0;
}
.droplinks:hover {
  color: blue;
  transition: all 0.3s ease-in;
}
.navbar-nav .dropdown-menu {
  background-color: #303983;
}
/* ---------------------newCarousel Css------------------ */
.ch {
  color: white;
}
.imgLeft {
  display: relative;
  display: flex;
  background-color: rgb(28 31 84 / 86%);
}
.imgLeft::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.7;
}
.lefti {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 1rem;

  color: white;
  z-index: 3;
  font-weight: 600;
}
.heading {
  font-size: 4rem;
  text-align: center;
}
.quote {
  text-align: center;
}
.caroImg {
  height: 70vh;
}
.capi {
  position: relative;
  top: -14rem;
  left: -21rem;
  z-index: 3;
}
.cbtn:hover {
  background-image: linear-gradient(-180deg, #9b89ff 0%, #4a54b1 100%);
  transition: all 0.3s ease-in;
}
.cbtn {
  background-color: #303983;
  cursor: pointer;
  position: relative;
  top: 1rem;
  padding: 1.5rem 2.5rem;
  border-radius: 3.75rem;
  line-height: 1.5rem;
  font-size: 1.8rem;
  border: 1px solid #012880;
  background-image: linear-gradient(-180deg, #9b89ff 0%, #303882 100%);
  box-shadow: 0rem 0.25rem 0 rgb(22 75 195 / 50%),
    0 -0.25rem 1.5rem rgb(19 17 143) inset,
    0 0.75rem 7.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem -0.5rem 0 rgb(70 144 207) inset;
}
.cbtn span {
  color: transparent;
  background-image: linear-gradient(0deg, #5c69a9 0%, #fefafd 100%);
  -webkit-background-clip: text;
  background-clip: text;

  color: white;
}
.cbtn::before {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
.cbtn::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;
  filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
@media (max-width: 576px) {
  .caroImg {
    height: 37vh;
    width: 100%;
  }
  .imgLeft {
    height: 37vh;
  }
  .capi {
    top: -8rem;
    left: 1rem;
  }
  .cbtn {
    padding: 1.3rem 2.3rem;
  }
}
@media (max-height: 1024px) and (min-height: 1023px) {
  .caroImg {
    height: 37vh;
  }
}
@media (max-height: 1369px) and (min-height: 1365px) {
  .caroImg {
    height: 37vh;
  }
  .capi {
    top: -16rem;
    left: 4rem;
  }
}
@media (max-height: 720px) and (min-height: 719px) {
  .caroImg {
    height: 37vh;
  }
  .capi {
    top: -7rem;
    left: -12rem;
  }
  .capi h1 {
    font-size: 1.2rem;
  }
}
@media (max-height: 1024px) and (min-height: 1023px) {
  .caroImg {
    width: 52%;
  }
  .capi {
    top: -11rem;
    left: -18rem;
  }
  .capi h1 {
    font-size: 1.8rem;
  }
}
@media (max-height: 601px) and (min-height: 599px) {
  .caroImg {
    width: 52%;
  }
  .capi {
    top: -13rem;
    left: -21rem;
  }
  .capi h1 {
    font-size: 1.8rem;
  }
}
@media (max-height: 801px) and (min-height: 799px) {
  .caroImg {
    width: 54%;
  }
  .capi {
    top: -19rem;
    left: -25rem;
  }
}
@media (max-height: 721px) and (min-height: 719px) {
  .caroImg {
    width: 54%;
  }
  .heading {
    font-size: 2rem;
  }
}
@media (max-height: 1181px) and (min-height: 1179px) {
  .capi {
    top: -32rem;
    left: 1rem;
  }
}
a.carousel-control-next {
  z-index: 5;
}

/* --------------------------Event Description-------------------- */
.MainEventCon {
  padding: 4rem;
}
.eventYear {
  margin: 2rem 0rem 0rem 2rem;
}
.eventYear h1 {
  font-size: xx-large;
}
.MainEventCon h1 {
  text-align: center;
}
.eventCon {
  padding: 0;
}
.LeftInnerCon {
  margin-top: 3rem;
  margin-bottom: 6rem;
  background-color: #f5f5f5;
  display: flex;
  border-radius: 1rem;
  padding: 3rem 2rem 2rem 2rem;
}
.RightInnerCon {
  margin-top: 3rem;
  margin-bottom: 6rem;
  background-color: #f5f5f5;
  display: flex;
  border-radius: 1rem;
  padding: 3rem 2rem 2rem 2rem;
}
.LeftInnerCon .eventCon p {
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: justify;
}
.RightInnerCon .eventCon p {
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: justify;
}
.EventImg {
  height: 60vh;
}
.eventHeading {
  margin: auto;
  width: 100%;
  color: #000000b0;
  border-radius: 0.6rem;
}
@media (max-width: 991px) {
  .MainEventCon {
    padding: 3rem;
  }
  .EventImg {
    height: 48vh;
  }
  .LeftInnerCon .eventCon p {
    font-size: 1rem;
  }
  .RightInnerCon .eventCon p {
    font-size: 1rem;
  }
}
@media (max-width: 864px) {
  .MainEventCon {
    padding: 3rem;
  }
  .EventImg {
    height: 48vh;
    margin: 2rem 2rem;
  }
  .LeftInnerCon {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .LeftInnerCon .eventCon p {
    font-size: 1rem;
  }
  .RightInnerCon {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .RightInnerCon .eventCon p {
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  .MainEventCon {
    padding: 3rem;
  }
  .EventImg {
    height: 32vh;
    margin: 2rem 2rem;
  }
  .LeftInnerCon {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .LeftInnerCon .eventCon p {
    font-size: 1rem;
  }
  .RightInnerCon {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .RightInnerCon .eventCon p {
    font-size: 1rem;
  }
  .eventCon {
    padding: 2rem 0rem;
  }
}

/* ------------------Full Gallery Page--------------- */
.galEventHead {
  margin: 4rem;
}

.galleryHead {
  font-size: 4rem;
}
.gallery {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 7rem;
}
.pics img {
  width: 100%;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;

  margin-bottom: 2rem;
}
.gallery .pics :hover {
  filter: opacity(0.8);
}
@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    padding: 0 3rem;
  }
}
@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
    padding: 0 3rem;
  }
}

/* Image model */
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-self: center;
  align-items: center;
  background-color: #000000bd;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;

  z-index: 999;
}
.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100vh;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}
/* .model .open svg {
} */
.crossGal {
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  cursor: pointer;
}
/* ---------------About page--------------------- */
.aboutDiv {
  background-color: #f5f5f5;
  margin: 3rem 5rem 1rem 5rem;
  padding: 1rem 2rem 2rem 2rem;
  border-radius: 2rem;
}
.aboutHeading {
  text-align: center;
  margin: 2rem;
  padding: 1rem 2rem 1rem 2rem;
}
.divDec {
  background-color: #f5f5f5;
}
.divDec p {
  text-align: justify;
}
@media (max-width: 991px) {
  .aboutDiv {
    padding: 1rem 1rem 2rem 1rem;
  }
}
@media (max-width: 480px) {
  .aboutDiv {
    margin: 3rem 2rem 1rem 2rem;
    padding: 1rem 0rem 2rem 0rem;
  }
}
