@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}
html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.inner {
  /* background-color: rgb(3, 3, 29);
    color: white;
    align-items: center;
    align-self: center;
    align-content: center;
    margin: auto;
    border: solid; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
-moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.75);
box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.75); */
}
.super-inner {
  /* text-align:justify;
    margin: auto;
    justify-content: center;
    padding: 35px 30px 35px 30px; */
  /* background-color: rgb(3, 3, 29); */
  /* margin-top: 10px; */
  /* padding-bottom: 15px; */
}
h3 {
  text-align: center;
  /* background-color: rgb(3, 3, 29); */
}
p {
  margin-left: 30px;
  margin-right: 30px;
}

.img-fluid {
  width: 20em;
  height: 70px;
  margin-left: -50px;
  padding-top: 70px;
}
/* .img-header{
    
    width: 100%;
    height:  600px;


} */

.miniabu {
  /* margin-top: 4em; */
  margin: 0rem 0rem;
  background: #ebebeb;
  padding: 6rem;
}
p {
  font-size: 14px;
}
.super-inner p {
  font-size: 1.5rem;
  text-align: justify;
  padding-left: 0;
}
.rm {
  text-align: center;
  text-decoration: none;
}
.super-inner h3 {
  font-size: 2rem;
  margin: 0rem 2rem 2rem 2rem;
}
@media (max-width: 768px) {
  .miniabu {
    padding: 4rem 1rem;
  }
}
strong {
  color: slateblue;
}
a {
  text-decoration: none;
}

.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.9)),
    url(/static/media/bitSindri.485466c7.png) fixed center center;
  background-size: cover;
  padding: 60px 0;
  margin-top: 15px;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
  padding-left: 0;
}

.cta .cta-btn {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #8fc04e;
  border: 2px solid #8fc04e;
}
#cta {
  margin-top: 15px;
}

.post{
  height: 89vh;
  text-align: center;
  padding: 3rem 0rem;
}

/* ___________________________ */
.box{
  width: 300px;
  box-shadow: 2px 2px 30px rgba(0,0,0,0.2);
  border-radius: 10px;
  overflow: hidden;
  margin: 13px 10px 17px 10px;
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%); */

}
.cardimg{
  height: 50vh;
  position: relative;

}
.cardimg img{
  /* width: 100%;
  height: 50vh; */
  object-fit: cover;
  box-sizing: border-box;
}
.detailbox{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.type1{
  display: flex;
  flex-direction: column;

}
.type1 a{
  color:#222222;
  margin: 5px 0px;
  font-weight: 700;
  letter-spacing: 0.5px ;
  padding-right: 8px;
}
.type1 span{
  color: rgba(26,26,26,0.5);
}


.swiper {
  width: 78%;
  height: 95%;
}


.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.ssi {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 806px) {
  .cardimg {
    height: 30vh;
    
}
.ssi {
  
  width: 100%;
  height: 100%;
  
}
.swiper{
  
  width: 78%;
  height: 78%;
}

}
@media screen and (max-width: 520px) {
 
  .box{
    margin: 0;
  }
  .cardimg {
    height: 15vh;
    
}
.post{
  height: 59vh;
}
.ssi {
  
  width: 100%;
  height: 100%;
  
}
.swiper{
  /* width: 92%;
  height: 50%; */
  width: 92%;
  height: 88%;
}
.swiper-slide{
  font-size: 9px;
}
}
.post_event_events{
  height: 110vh;
  text-align: center;
  padding: 3rem 0rem;
  width: 100%;
  overflow: hidden;
}

.eventCard{
  height: 86vh;
  background-color: #f8fbf4;
  margin: 2rem 1rem;
  border-radius: 2rem;
  padding: 0rem 2rem;
}
.eventCard:hover{
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.top_side{
  display: flex;
  justify-content: center;
}
.top_side img{
  width: 78%;
}
.bottom_side{
  padding: 1rem;
  text-align: justify;
}
.read{
  text-align: center;
}
.bottom_side strong{
  color: black;
}
.bottom_side h2{
  text-align: center;
}
@media (max-width: 768px) {
  .eventCard{
    height: 55vh;
  }
  .post_event_events{
    height: 71vh;
  }

}
@media (max-width: 394px) {
  .eventCard{
    height: 60vh;
  }
  .post_event_events{
    height: 78vh;
  }

}
@media (max-width: 378px) {
  .eventCard{
    height: 78vh;
  }
  .post_event_events{
    height: 97vh;    
  }

}
.testimonials {
    padding: 80px 0;
    /* background: url("../img/bitbuilding.jpeg") no-repeat; */
    background-position:  center bottom;
    background-size: cover;
    position: relative;
  }
  .testh4{
    color: white;
  }
  .testimonials::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(2, 2, 2, 0.7);
  }
  
  .testimonials .section-header {
    margin-bottom: 40px;
  }
  .testimonial-item{
    background-color:  rgba(255, 255, 255, 0);;
    width: 100%;
  }
  .testimonials .testimonial-item {
    text-align: center;
    color: rgba(255, 255, 255, 0);
  }
  .testimonial-img{
    width: 30%;
    border-radius: 50%;
    border: 6px solid rgba(255, 255, 255, 0.15);
    margin: 0 auto; 
   
  }

  .testimonials .testimonial-item  {
    width: 200px;
    border-radius: 50%;
    border: 6px solid rgba(255, 255, 255, 0.15);
    margin: 0 auto;
    
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #fff;
  }
  
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #ddd;
    margin: 0 0 15px 0;
  }
  
  .testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
    color: rgba(255, 255, 255, 0.4);
    font-size: 26px;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
    color: #eee;
  }
  
  .testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 1;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #8fc04e;
  }
  
  
  @media (min-width: 992px) {
    .testimonials .testimonial-item p {
      width: 80%;
    }
   
  }
  @media screen and (max-width: 520px) {
    .testimonial-img{
        width: 40%;

    }
  }
.full{
  margin: 0;
  /* margin-top: -2em; */
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins" , sans-serif;
}
/* .full{
  margin-bottom: 20.1em;
  background-color: white;
} */
#thank-you-message {
  display: none;
}


#thank-you-message.show {
  color: rgb(73, 234, 24);
  font-weight: bolder;
	text-align: center;
  font-family: monospace;
	padding: 10px;
	background: #02c74a;
	color: rgb(255, 255, 255);
	margin-top: 25px;
  margin-left: -14px;
  margin-right: -23px;
	display: none;
  border-color: #000000;
  border-radius: 10px;
  display: block;
}

.body1{
  min-height: 100vh;
  width: 100%;
  background: #e4edf7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -8em;
  padding-top: -2em;
}
.container1{
  /* width: 80%; */
  background: #fff;
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.container1 .content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container1 .content .left-side{
  /* width: 25%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.content .left-side::before{
  content: '';
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #afafb6;
}
.content .left-side .details{
  margin: 14px;
  text-align: center;
}
.content .left-side .details i{
  font-size: 30px;
  color: #3e2093;
  margin-bottom: 10px;
}
.content .left-side .details .topic{
  font-size: 18px;
  font-weight: 500;
}
.content .left-side .details .text-one,
.content .left-side .details .text-two{
  font-size: 14px;
  color: #afafb6;
}
.container1 .content .right-side{
  width: 75%;
  margin-left: 75px;
}
.content .right-side .topic-text{
  font-size: 23px;
  font-weight: 600;
  color: #3e2093;
}
.right-side .input-box{
  height: 50px;
  width: 100%;
  padding-left: 2em;
  margin-right: 0;
  margin: 12px 0;
}
.button{
  padding-left: 8em;

}
/* .pp{
  padding-left: 8em;
} */
.right-side .input-box input,
.right-side .input-box textarea{
  height: 100%;
  width: 94%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #F0F1F8;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.right-side .message-box{
  min-height: 110px;
}
.right-side .input-box textarea{
  padding-top: 6px;
}
.right-side .button{
  display: inline-block;
  margin-top: 12px;
}
.right-side .button button[type="button"]{
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #3e2093;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button button[type="button"]:hover{
  background: #5029bc;
}

@media (max-width: 950px) {
  .container1{
    width: 90%;
    padding: 30px 40px 40px 35px ;
  }
  .container1 .content .right-side{
   width: 75%;
   margin-left: 55px;
}
}
@media (max-width: 820px) {
  .container1{
    margin: 40px 0;
    height: 100%;
  }
  .container1 .content{
    flex-direction: column-reverse;
  }
 .container1 .content .left-side{
   width: 100%;
   flex-direction: row;
   margin-top: 40px;
   justify-content: center;
   flex-wrap: wrap;
 }
 .container1 .content .left-side::before{
   display: none;
 }
 .container1 .content .right-side{
   width: 100%;
   margin-left: 0;
 }
}


/* -------------------------NavBar css------------------- */
.container-fluid {
  background-color: #303983cc;
  position: absolute;
  top: -0.1rem;
  left: 0rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  width: 2em;
  height: 2em;
  border-radius: 0.5rem;
}

.navbar {
  z-index: 99;
  background-color: #303983;
  padding: 0.5rem 2rem;
  height: 8.4vh;
}
@media (max-width: 576px) {
  .navbar {
    height: 5.5vh;
  }
}
@media (max-width: 411px) {
  .navbar {
    height: 6vh;
  }
}
@media (max-width: 360px) {
  .navbar {
    height: 7vh;
  }
}
@media (max-height: 801px) and (min-height: 799px) {
  .navbar {
    height: 7.3vh;
  }
}
@media (max-height: 667px) and (min-height: 666px) {
  .navbar {
    height: 7.6vh;
  }
}
@media (max-height: 1024px) and (min-height: 1023px) {
  .navbar {
    height: 5.8vh;
  }
}
@media (max-height: 720px) and (min-height: 719px) {
  .navbar {
    height: 7vh;
  }
}
@media (max-height: 1181px) and (min-height: 1179px) {
  .navbar {
    height: 5vh;
  }
}
@media (max-height: 1369px) and (min-height: 1367px) {
  .navbar {
    height: 4.3vh;
  }
}
.navbar-brand {
  margin: 0rem 1.5rem;
}
.navbar-brand a:hover {
  transition: all 0.2s ease-in;
}
.navbar .navbar-brand img {
  margin: 0 0.9rem;
}
@media (max-width: 576px) {
  .navbar .navbar-brand img {
    margin: 0 0.9rem 0 0.3rem;
  }
  .navBit {
    height: 32px;
    width: 27px;
  }
  .navNss {
    height: 31px;
    width: 31px;
  }
}
.navbar a {
  color: white;
  font-size: 1.7rem;
  padding: 1rem 0rem;
}
.navbar a:hover {
  transition: all 0.2s ease-in;
  color: #6199eb;
}
.navbar-nav .nav-link {
  margin-right: 2.5rem;
}

@media (min-width: 576px) and (max-width: 826px) {
  .navbar a {
    font-size: 1rem;
  }
  .navbar-nav .nav-link {
    margin-right: 1.5rem;
  }
}
@media (max-width: 342px) {
  .navbar a {
    font-size: 1.7rem;
  }
}
.droplinks {
  color: white;
  padding: 0rem 1rem;
  margin-bottom: 0;
}
.droplinks:hover {
  color: blue;
  transition: all 0.3s ease-in;
}
.navbar-nav .dropdown-menu {
  background-color: #303983;
}
/* ---------------------newCarousel Css------------------ */
.ch {
  color: white;
}
.imgLeft {
  display: relative;
  display: flex;
  background-color: rgb(28 31 84 / 86%);
}
.imgLeft::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.7;
}
.lefti {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 1rem;

  color: white;
  z-index: 3;
  font-weight: 600;
}
.heading {
  font-size: 4rem;
  text-align: center;
}
.quote {
  text-align: center;
}
.caroImg {
  height: 70vh;
}
.capi {
  position: relative;
  top: -14rem;
  left: -21rem;
  z-index: 3;
}
.cbtn:hover {
  background-image: linear-gradient(-180deg, #9b89ff 0%, #4a54b1 100%);
  transition: all 0.3s ease-in;
}
.cbtn {
  background-color: #303983;
  cursor: pointer;
  position: relative;
  top: 1rem;
  padding: 1.5rem 2.5rem;
  border-radius: 3.75rem;
  line-height: 1.5rem;
  font-size: 1.8rem;
  border: 1px solid #012880;
  background-image: linear-gradient(-180deg, #9b89ff 0%, #303882 100%);
  box-shadow: 0rem 0.25rem 0 rgb(22 75 195 / 50%),
    0 -0.25rem 1.5rem rgb(19 17 143) inset,
    0 0.75rem 7.5rem rgb(255 255 255 / 40%) inset,
    0 0.25rem -0.5rem 0 rgb(70 144 207) inset;
}
.cbtn span {
  color: transparent;
  background-image: linear-gradient(0deg, #5c69a9 0%, #fefafd 100%);
  -webkit-background-clip: text;
  background-clip: text;

  color: white;
}
.cbtn::before {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
.cbtn::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(100% - 7.5rem);
  background: #fff;
  border-radius: 100%;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
@media (max-width: 576px) {
  .caroImg {
    height: 37vh;
    width: 100%;
  }
  .imgLeft {
    height: 37vh;
  }
  .capi {
    top: -8rem;
    left: 1rem;
  }
  .cbtn {
    padding: 1.3rem 2.3rem;
  }
}
@media (max-height: 1024px) and (min-height: 1023px) {
  .caroImg {
    height: 37vh;
  }
}
@media (max-height: 1369px) and (min-height: 1365px) {
  .caroImg {
    height: 37vh;
  }
  .capi {
    top: -16rem;
    left: 4rem;
  }
}
@media (max-height: 720px) and (min-height: 719px) {
  .caroImg {
    height: 37vh;
  }
  .capi {
    top: -7rem;
    left: -12rem;
  }
  .capi h1 {
    font-size: 1.2rem;
  }
}
@media (max-height: 1024px) and (min-height: 1023px) {
  .caroImg {
    width: 52%;
  }
  .capi {
    top: -11rem;
    left: -18rem;
  }
  .capi h1 {
    font-size: 1.8rem;
  }
}
@media (max-height: 601px) and (min-height: 599px) {
  .caroImg {
    width: 52%;
  }
  .capi {
    top: -13rem;
    left: -21rem;
  }
  .capi h1 {
    font-size: 1.8rem;
  }
}
@media (max-height: 801px) and (min-height: 799px) {
  .caroImg {
    width: 54%;
  }
  .capi {
    top: -19rem;
    left: -25rem;
  }
}
@media (max-height: 721px) and (min-height: 719px) {
  .caroImg {
    width: 54%;
  }
  .heading {
    font-size: 2rem;
  }
}
@media (max-height: 1181px) and (min-height: 1179px) {
  .capi {
    top: -32rem;
    left: 1rem;
  }
}
a.carousel-control-next {
  z-index: 5;
}

/* --------------------------Event Description-------------------- */
.MainEventCon {
  padding: 4rem;
}
.eventYear {
  margin: 2rem 0rem 0rem 2rem;
}
.eventYear h1 {
  font-size: xx-large;
}
.MainEventCon h1 {
  text-align: center;
}
.eventCon {
  padding: 0;
}
.LeftInnerCon {
  margin-top: 3rem;
  margin-bottom: 6rem;
  background-color: #f5f5f5;
  display: flex;
  border-radius: 1rem;
  padding: 3rem 2rem 2rem 2rem;
}
.RightInnerCon {
  margin-top: 3rem;
  margin-bottom: 6rem;
  background-color: #f5f5f5;
  display: flex;
  border-radius: 1rem;
  padding: 3rem 2rem 2rem 2rem;
}
.LeftInnerCon .eventCon p {
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: justify;
}
.RightInnerCon .eventCon p {
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: justify;
}
.EventImg {
  height: 60vh;
}
.eventHeading {
  margin: auto;
  width: 100%;
  color: #000000b0;
  border-radius: 0.6rem;
}
@media (max-width: 991px) {
  .MainEventCon {
    padding: 3rem;
  }
  .EventImg {
    height: 48vh;
  }
  .LeftInnerCon .eventCon p {
    font-size: 1rem;
  }
  .RightInnerCon .eventCon p {
    font-size: 1rem;
  }
}
@media (max-width: 864px) {
  .MainEventCon {
    padding: 3rem;
  }
  .EventImg {
    height: 48vh;
    margin: 2rem 2rem;
  }
  .LeftInnerCon {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .LeftInnerCon .eventCon p {
    font-size: 1rem;
  }
  .RightInnerCon {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .RightInnerCon .eventCon p {
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  .MainEventCon {
    padding: 3rem;
  }
  .EventImg {
    height: 32vh;
    margin: 2rem 2rem;
  }
  .LeftInnerCon {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .LeftInnerCon .eventCon p {
    font-size: 1rem;
  }
  .RightInnerCon {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .RightInnerCon .eventCon p {
    font-size: 1rem;
  }
  .eventCon {
    padding: 2rem 0rem;
  }
}

/* ------------------Full Gallery Page--------------- */
.galEventHead {
  margin: 4rem;
}

.galleryHead {
  font-size: 4rem;
}
.gallery {
  -webkit-column-count: 4;
  column-count: 4;
  -webkit-column-width: 33%;
  column-width: 33%;
  padding: 0 7rem;
}
.pics img {
  width: 100%;
  transition: all 350ms ease;
  cursor: pointer;

  margin-bottom: 2rem;
}
.gallery .pics :hover {
  -webkit-filter: opacity(0.8);
          filter: opacity(0.8);
}
@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 2;
    column-count: 2;
    padding: 0 3rem;
  }
}
@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    column-width: 100%;
    padding: 0 3rem;
  }
}

/* Image model */
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-self: center;
  align-items: center;
  background-color: #000000bd;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    -webkit-transform 0.3s ease-in-out;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.3s ease-in-out;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  overflow: hidden;

  z-index: 999;
}
.model.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100vh;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}
/* .model .open svg {
} */
.crossGal {
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  cursor: pointer;
}
/* ---------------About page--------------------- */
.aboutDiv {
  background-color: #f5f5f5;
  margin: 3rem 5rem 1rem 5rem;
  padding: 1rem 2rem 2rem 2rem;
  border-radius: 2rem;
}
.aboutHeading {
  text-align: center;
  margin: 2rem;
  padding: 1rem 2rem 1rem 2rem;
}
.divDec {
  background-color: #f5f5f5;
}
.divDec p {
  text-align: justify;
}
@media (max-width: 991px) {
  .aboutDiv {
    padding: 1rem 1rem 2rem 1rem;
  }
}
@media (max-width: 480px) {
  .aboutDiv {
    margin: 3rem 2rem 1rem 2rem;
    padding: 1rem 0rem 2rem 0rem;
  }
}

.h1 {
  color: #5d8bf4;
  font-size: 3.5rem;
  padding-top: 3rem;
  text-align: center;
}
h5 {
  font-size: 3rem;
}
.card-title {
  font-size: 2rem;
}
.card-title1 {
  font-size: 1.8rem;
}
.card-title2 {
  font-size: 1.8rem;
}
.card-title3 {
  font-size: 1.7rem;
}
.card {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 14px 80px 18px 36px;
  cursor: pointer;
}

.card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.mem {
  width: 100%;
}

.row-footer {
  display: inline-flexbox;
}
ul,
li {
  list-style: none;
}
.cover {
  background-color: rgb(5, 5, 39);
  margin: 0;
  color: white;
  padding-top: 20px;
  padding-bottom: 0;
  width: 100%;
  /* background-color: rebeccapurple; */
}
.colcircle {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
}
.colcircle img {
  margin-top: 0.8rem;
  height: 2.5rem;
}
.full {
  background-color: rgb(5, 5, 39);
  width: 100%;
  padding-top: 2em;
  margin-top: 40px;
  /* background-color: red; */
}
.copy {
  font-size: 10px;
}
.sizeofdiv {
  margin-top: 3em;
  /* width:2em; */
  width: 400px;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  align-content: center;
  align-self: center;
  text-align: center;
  margin: auto;
}
.pro {
  width: 100%;

  margin-top: 40px;
}
.first {
  background-color: rgb(6, 6, 39);
  color: white;
}
.pro-inner {
  background-color: black;
}
.copyright {
  text-align: center;
  margin-top: 3em;
  color: burlywood;
}
hr {
  width: 300px;
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
  margin-left: 50px;
  padding-left: 50px;
  text-align: right;
}
.linkcolor {
  color: ghostwhite;
  list-style: none;
  font-style: none;
  font-size: 11.5px;
  font-style: none;
  margin-bottom: 13px;
  text-decoration: none;
}
.mar {
  font-style: 20px;
  margin-bottom: 23px;
}
/* .compLinkas {
  background-color: aqua;
} */
.row h2 {
  display: flex;
  justify-content: center;
  margin: 5rem 2rem 1rem 2rem;
  font-size: 2rem;
}
footer {
  padding: 4rem 0 2rem 0 rem;
  background-color: rgb(5, 5, 39);
  color: white;
}
.foot {
  /* background-color: yellowgreen; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
/* .foot a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
} */
.lit {
  margin-right: 0.7rem;
}
.social {
  /* background-color: rebeccapurple; */
  padding: 1rem 2rem;
}
@media (max-width: 576px) {
  .copy {
    /* background-color: red; */
    font-size: 1rem;
  }
}

.team {
  /* background: #fff; */
  /* background-color: rgb(69, 70, 83); */
  padding: 60px 0;
}
.team_img {
  /* padding-top: 0px;
    margin-left: 0px;
    height: 24vh; */
  padding-top: 0px;
  margin-left: 1px;
  height: 28vh;
}
.more_pad {
  margin-left: 13px;
}
.more_pad1 {
  margin-left: 22px;
}
.more_pad2 {
  margin-left: 6px;
}
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #353535;
  margin: 0 3px;
  border-radius: 50px;
  width: 36px;
  height: 36px;
  background: rgba(143, 192, 78, 0.8);
  display: inline-block;
  transition: ease-in-out 0.3s;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.team .member .social a:hover {
  background: #4e7cc0;
}

.team .member .social i {
  font-size: 18px;
  line-height: 0;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #353535;
}

.team .member .member-info span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #878686;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}
/* from teams section for mobile view */

@media (max-width: 1200px) {
  .team_img {
    width: 30em;
  }
}
@media (max-width: 768px) {
  .team_img {
    width: 20em;
  }
}
@media (max-width: 821px) {
  .team_img {
    height: 26vh;
  }
}
@media (max-width: 500px) {
  .team_img {
    height: 22vh;
  }
}
@media (max-width: 376px) {
  .team_img {
    height: 30vh;
  }
}
@media (max-width: 281px) {
  .team_img {
    height: 21vh;
  }
}
@media (max-width: 768px) {
  .team {
    padding: 60px 61px;
  }
}
/* @media (max-width: 320px) {
    .team {
        display:none;
    }
  } */

