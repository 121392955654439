.row-footer {
  display: inline-flexbox;
}
ul,
li {
  list-style: none;
}
.cover {
  background-color: rgb(5, 5, 39);
  margin: 0;
  color: white;
  padding-top: 20px;
  padding-bottom: 0;
  width: 100%;
  /* background-color: rebeccapurple; */
}
.colcircle {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
}
.colcircle img {
  margin-top: 0.8rem;
  height: 2.5rem;
}
.full {
  background-color: rgb(5, 5, 39);
  width: 100%;
  padding-top: 2em;
  margin-top: 40px;
  /* background-color: red; */
}
.copy {
  font-size: 10px;
}
.sizeofdiv {
  margin-top: 3em;
  /* width:2em; */
  width: 400px;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  align-content: center;
  align-self: center;
  text-align: center;
  margin: auto;
}
.pro {
  width: 100%;

  margin-top: 40px;
}
.first {
  background-color: rgb(6, 6, 39);
  color: white;
}
.pro-inner {
  background-color: black;
}
.copyright {
  text-align: center;
  margin-top: 3em;
  color: burlywood;
}
hr {
  width: 300px;
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
  margin-left: 50px;
  padding-left: 50px;
  text-align: right;
}
.linkcolor {
  color: ghostwhite;
  list-style: none;
  font-style: none;
  font-size: 11.5px;
  font-style: none;
  margin-bottom: 13px;
  text-decoration: none;
}
.mar {
  font-style: 20px;
  margin-bottom: 23px;
}
/* .compLinkas {
  background-color: aqua;
} */
.row h2 {
  display: flex;
  justify-content: center;
  margin: 5rem 2rem 1rem 2rem;
  font-size: 2rem;
}
footer {
  padding: 4rem 0 2rem 0 rem;
  background-color: rgb(5, 5, 39);
  color: white;
}
.foot {
  /* background-color: yellowgreen; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
/* .foot a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
} */
.lit {
  margin-right: 0.7rem;
}
.social {
  /* background-color: rebeccapurple; */
  padding: 1rem 2rem;
}
@media (max-width: 576px) {
  .copy {
    /* background-color: red; */
    font-size: 1rem;
  }
}
