.team {
  /* background: #fff; */
  /* background-color: rgb(69, 70, 83); */
  padding: 60px 0;
}
.team_img {
  /* padding-top: 0px;
    margin-left: 0px;
    height: 24vh; */
  padding-top: 0px;
  margin-left: 1px;
  height: 28vh;
}
.more_pad {
  margin-left: 13px;
}
.more_pad1 {
  margin-left: 22px;
}
.more_pad2 {
  margin-left: 6px;
}
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #353535;
  margin: 0 3px;
  border-radius: 50px;
  width: 36px;
  height: 36px;
  background: rgba(143, 192, 78, 0.8);
  display: inline-block;
  transition: ease-in-out 0.3s;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.team .member .social a:hover {
  background: #4e7cc0;
}

.team .member .social i {
  font-size: 18px;
  line-height: 0;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #353535;
}

.team .member .member-info span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #878686;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}
/* from teams section for mobile view */

@media (max-width: 1200px) {
  .team_img {
    width: 30em;
  }
}
@media (max-width: 768px) {
  .team_img {
    width: 20em;
  }
}
@media (max-width: 821px) {
  .team_img {
    height: 26vh;
  }
}
@media (max-width: 500px) {
  .team_img {
    height: 22vh;
  }
}
@media (max-width: 376px) {
  .team_img {
    height: 30vh;
  }
}
@media (max-width: 281px) {
  .team_img {
    height: 21vh;
  }
}
@media (max-width: 768px) {
  .team {
    padding: 60px 61px;
  }
}
/* @media (max-width: 320px) {
    .team {
        display:none;
    }
  } */
